import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import {getImage, GatsbyImage} from 'gatsby-plugin-image'
import { kebabCase } from 'lodash'
import { createLinkStyle } from '../functions/createconst'
import * as styles from '../../styles/popularlist.module.scss'

const PopularList = ({slugs}) =>{
    const LinkStyle = createLinkStyle()

    const query = useStaticQuery(
        graphql`
        query {
            allMdx {
                nodes {
                    slug
                    frontmatter {
                        category1
                        category2
                        title
                        hero {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }`    
    )

    const nodes = query.allMdx.nodes;
    const items = [];

    for(let i = 0 ; i < slugs.length; i++){
        for(let j = 0 ; j < nodes.length; j++){
            if(slugs[i] === nodes[j].slug){
                const category1 = kebabCase(nodes[j].frontmatter.category1);
                const category2 = kebabCase(nodes[j].frontmatter.category2);
                const newitem = {
                    title: nodes[j].frontmatter.title,
                    image: nodes[j].frontmatter.hero,
                    url: `/${category1}/${category2}/${slugs[i]}`
                };
                items.push(newitem);
                break;
            }    
        }
    }

    return(
        <div className={styles.popularlistwrapper}>
            <div className={styles.title}>
                <div className={styles.titletext}>人気記事</div>
            </div>
            <div className={styles.flexwrapper}>
                {items.map((item) => 
                        <a href={item.url} target = "_blank" rel="noopener norefferer" style={LinkStyle}>
                            <div className = {styles.linkwrapper}>
                                <GatsbyImage className = {styles.imagestyle}
                                    alt = "thumbnailimg" 
                                    image = {getImage(item.image)}
                                    width = {200}
                                />
                                <div className={styles.articletitle}>{item.title}</div>
                            </div>
                        </a>
                    
                )}
            </div>
        </div>
    )
}

export default PopularList