import React, { useEffect, useState} from 'react'
import * as styles from '../../styles/adsense.module.scss'


const AdSense = ({path}) => {
    // const [state, setState] = useState({ showAds: false })

    useEffect(() => {
        //-------------
        // const getgoogleads = async() => {
        //     window.adsbygoogle = await window.adsbygoogle || []
        //     window.adsbygoogle.push({})
        // }
        //-------------
        // if (state.showAds) {
        //     window.adsbygoogle = window.adsbygoogle || []
        //     window.adsbygoogle.push({})
        //     // getgoogleads();
        // }
        if (window) {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})

            // setState({showAds: true})
        }
    },[path]) 
    //[state.showAds])

    // useEffect(() => {
    //     if(window){
    //         window.adsbygoogle = window.adsbygoogle || []
    //         window.adsbygoogle.push({})
    //     }
    // },[path])

    // org-------
    return (
        <div className={styles.adsensewrapper}
            //key={currentPath}
            key = {path}
        >
            <ins className="adsbygoogle"
                style={{display: 'block'}}
                data-ad-client="ca-pub-6562620686181061"
                data-ad-slot="6672514083"
                data-ad-format="auto"
                data-full-width-responsive="true"
            />
        </div>
    )    
    
}

export default AdSense