import React from "react";
import * as styles from "../../styles/affilistyle.module.scss";

const AffiliLiq = () => {
  return(
    <div className={styles.affiliwrapper}>
      <h3>お酒</h3>
      <p>お酒も大好き！お酒に関しても面白いサービスが沢山あります！</p>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3NJ3GZ+1DF1J6+4XM6+60OXD" rel="nofollow">
          <img border="0" width="300" height="250" alt="" src="https://www22.a8.net/svt/bgt?aid=220920659083&wid=003&eno=01&mid=s00000023019001011000&mc=1"/></a>
          <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3NJ3GZ+1DF1J6+4XM6+60OXD" alt=""/>
        </div>
        <div className={styles.affilitext}>
          私はもっぱらビール派なのですが、ビールって<span className={styles.rm}>飲む頻度が高いと、結構面倒</span>なんですよね。特に買いに行くのが…買い溜めも大変だし、鮮度も落ちるから、そんなに沢山買い溜められないし…。こちらのサイトでは、<b>クラフトビールを定期便で送ってくれます！</b><span className={styles.bm}>好みも伝えられるし、配送のスキップもでき、とてもユーザーフレンドリーなサービスですね！</span>
        </div>
      </div>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3T0FGB+7GIILU+4LPU+64C3L" rel="nofollow">
            <img border="0" width="300" height="250" alt="" src="https://www22.a8.net/svt/bgt?aid=230127419451&wid=001&eno=01&mid=s00000021477001028000&mc=1"/></a>
            <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=3T0FGB+7GIILU+4LPU+64C3L" alt=""/>
        </div>
        <div className={styles.affilitext}>
        ワインは思い出したように何本か買ってきて飲む時期があります笑。<b>「詳しくはないけど、美味しいワイン飲みたいな」という人にはうってつけのサービス</b>です！ソムリエがオススメワインをチョイスしてくれます！
        </div>
      </div>
    </div>
  )
}

export default AffiliLiq