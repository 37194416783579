import React from 'react'
import {
    FacebookShareButton,
    FacebookIcon,
    TwitterShareButton,
    TwitterIcon,
    //WhatsappShareButton,
    //WhatsappIcon,
    LineShareButton,
    LineIcon,
    LinkedinShareButton,
    LinkedinIcon,
    //RedditShareButton,
    //RedditIcon
} from 'react-share';

import * as styles from '../styles/snspostshare.module.scss'


const SnsPostShare = ({ articleUrl, articleTitle }) => (
    <div className={styles.snspostshare}>
    
    <div className={styles.leadtext}>よかったらシェアしてください！</div>

    <div className={styles.iconflexwrapper}>
        <div className={styles.iconwrapper}>
            <FacebookShareButton url={articleUrl}>
            <FacebookIcon size={32} round />
            </FacebookShareButton>
        </div>

        <div className={styles.iconwrapper}>
            <LineShareButton url={articleUrl}>
            <LineIcon size={32} round />
            </LineShareButton>            
        </div>

        <div className={styles.iconwrapper}>
            <LinkedinShareButton url={articleUrl}>
            <LinkedinIcon title={articleTitle} size={32} round />
            </LinkedinShareButton>
        </div>

        <div className={styles.iconwrapper}>
            <TwitterShareButton title={articleTitle} via="moto_kindj" url={articleUrl}>
            <TwitterIcon size={32} round />
            </TwitterShareButton>
        </div>

    </div>
    </div>
);

export default SnsPostShare