import React from "react";
import * as styles from "../../styles/affilistyle.module.scss";

const AffiliOutdoor = () => {
  return(
    <div className={styles.affiliwrapper}>
      <h3>アウトドア用品</h3>
      <p>季節がいい日はよくキャンプに行きます！アウトドア用品ってなんであんなに魅力的なんでしょうね…。ということで、アウトドアに関する紹介です！</p>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3T20MH+8WWNUA+52D0+5ZMCH" rel="nofollow">
          <img border="0" width="300" height="250" alt="" src="https://www26.a8.net/svt/bgt?aid=230201513539&wid=001&eno=01&mid=s00000023634001006000&mc=1" /></a>
          <img border="0" width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=3T20MH+8WWNUA+52D0+5ZMCH" alt="" />
        </div>
        <div className={styles.affilitext}>
          こちらのサイトはキャンプ用品はもちろん、リュックや小物、服なども扱っていますが、<b>どれもキャンプ向けのテイストで揃えており、とてもオシャレです！</b><span className={styles.bm}>キャンプに便利な食料品もあり、見ているだけで楽しくなります！</span>
        </div>
      </div>
      
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3T0EO4+7MGUNM+2TOK+3T0P5T" rel="nofollow">
            <img border="0" width="300" height="250" alt="" src="https://www23.a8.net/svt/bgt?aid=230126404461&wid=001&eno=01&mid=s00000013178023014000&mc=1"/>
          </a>
            <img border="0" width="1" height="1" src="https://www15.a8.net/0.gif?a8mat=3T0EO4+7MGUNM+2TOK+3T0P5T" alt=""/>
        </div>
        <div className={styles.affilitext}>
          キャンプ用品って、つい買っちゃいますよね…。<span className={styles.rm}>まだ使えそうなのに買っちゃったり…。</span>こちらのサイトでは、箱に詰めて送るだけでアウトドア用品を買い取ってくれます！<span className={styles.bmb}>梱包用の箱も送料も無料、ということで、太っ腹ですね！</span>
        </div>
      </div>
    </div>
  )
}

export default AffiliOutdoor