

const ABtest = ({ratio, a, b}) =>{
  const randomvalue = Math.floor(Math.random()*99)+1;
  let randomchild;

  if(randomvalue <= ratio){
    randomchild = a;
  }else{
    randomchild = b;
  }

  return(
    randomchild
  )
}

export default ABtest