import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from '../../styles/linestamp.module.scss';

const LineStampLink = () => {


    return(
        <div className = {styles.linestampwrapper}>
            <a href = "https://line.me/S/shop/sticker/author/3245917" target = "_blank" rel="noopener noreferrer">
                <div className = {styles.linkwrapper}>
                    <div className = {styles.leadtext}>LINEスタンプも販売中！ぜひチェックしてください！</div>
                    <StaticImage src = "../../images/linestamp.png"
                    />
                </div>
            </a>
        </div>
    )
}

export default LineStampLink;