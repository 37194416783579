import React from "react";
import * as styles from "../../styles/affilistyle.module.scss";

const AffiliDrink = () => {
  return(
    <div className={styles.affiliwrapper}>
      <h3>コーヒー</h3>
      <p>バイカー大好き、コーヒー！簡単な缶コーヒーもいいですが、コーヒーもちょっとこだわってみると面白いです！キャンプなどのアウトドアとの相性も抜群です。</p>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3T0EO6+ES9JLE+4O84+601S1" rel="nofollow">
          <img border="0" width="300" height="250" alt="" src="https://www28.a8.net/svt/bgt?aid=230126406894&wid=001&eno=01&mid=s00000021802001008000&mc=1"/></a>
          <img border="0" width="1" height="1" src="https://www17.a8.net/0.gif?a8mat=3T0EO6+ES9JLE+4O84+601S1" alt=""/>
        </div>
        <div className={styles.affilitext}>
          「本格的なコーヒーが飲みたい！」と思っても、<span className={styles.rm}>どんな豆がいいのかって、結構わかんないし、迷う</span>んですよね。このサービスを使えば<b>バリスタが自分好みのコーヒーを選んで届けてくれます！</b>
        </div>
      </div>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
          <a href="https://px.a8.net/svt/ejp?a8mat=3NJ3GZ+4UG2KI+3NWC+639IP" rel="nofollow">
            <img border="0" width="300" height="250" alt="" src="https://www28.a8.net/svt/bgt?aid=220920659293&wid=003&eno=01&mid=s00000017094001023000&mc=1"/></a>
            <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3NJ3GZ+4UG2KI+3NWC+639IP" alt=""/>
        </div>
        <div className={styles.affilitext}>
          「美味しいコーヒーが飲みたいけど、ドリップするのは面倒…」という方にはこちら。<b>本格的な味のコーヒーが手軽にいただけます！</b><span className={styles.bmb}>オシャレですし、お菓子も売っているので、ギフトにも最適です！</span>
        </div>
      </div>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
        <a href="https://px.a8.net/svt/ejp?a8mat=3NJ48R+8WB9S2+4IHG+60H7L" rel="nofollow">
        <img border="0" width="300" height="250" alt="" src="https://www25.a8.net/svt/bgt?aid=220921659538&wid=003&eno=01&mid=s00000021058001010000&mc=1"/></a>
        <img border="0" width="1" height="1" src="https://www12.a8.net/0.gif?a8mat=3NJ48R+8WB9S2+4IHG+60H7L" alt=""/>
        </div>
        <div className={styles.affilitext}>
          美味しいコーヒー淹れるには美味しい水！ウチも浄水器を使っていますが、いいですよ！水がいいと、コーヒーだけでなく、料理や氷など<b>日常生活も気持ちよく送れます！</b>
        </div>
      </div>
    </div>
  )
}

export default AffiliDrink