import React from "react";
import { motion } from "framer-motion";

const SidebarIcon = ({
    isOpen = false,
    width = 20,
    height = 20,
    strokeWidth = 2,
    color = "#000",
    transition = null,
    lineProps = null,
    ...props
}) => {
    const variant = isOpen ? "opened" : "closed";
    const top = {
    closed: {
        rotate: 0,
        translateY: 0
    },
    opened: {
        rotate: 180,
        // translateX: 4,
        translateY: 0.3,

    }
    };

    const bottom = {
    closed: {
        rotate: 0,
        translateY: 0
    },
    opened: {
        rotate: 180,
        // translateX: 4,
        translateY: 0.1,

    }
    };
    lineProps = {
    stroke: color,
    strokeWidth: strokeWidth,
    vectorEffect: "non-scaling-stroke",
    initial: "closed",
    animate: variant,
    transition,
    ...lineProps
    };
    const unitHeight = 4;
//    const unitWidth = (unitHeight * (width)) / (height);
    let unitWidth;
    if(!isOpen){
        unitWidth = unitHeight;
    }
    else if(isOpen){
        unitWidth = unitHeight * 1.41421356;
    }


    return (
    <motion.svg
//        viewBox={`0 0 ${unitWidth} ${unitHeight}`}
        viewBox={`0 0 4 4`}
        overflow="visible"
        preserveAspectRatio="none"
        width={width}
        height={height}
        //{...props}
    >
    <motion.polyline
        points="0,0 2,2 4,0"
        fill = "none"
        variants={top}
        {...lineProps}
    />
    <motion.polyline
        points="0,2 2,4 4,2"
        fill = "none"
        variants={bottom}
        {...lineProps}
    />
    </motion.svg>
);
};

export default SidebarIcon
