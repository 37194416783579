import React from "react";
import * as styles from "../../styles/affilistyle.module.scss";

const AffiliTravel = () => {
  return(
    <div className={styles.affiliwrapper}>
      <h3>レジャー</h3>
      <p>そろそろ外でご飯食べたい！遊びたい！ということで、食事やレジャーの紹介です。</p>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
        <a href="https://px.a8.net/svt/ejp?a8mat=3NJ3GY+CP8YWY+1OK+OG23L" rel="nofollow">
        <img border="0" width="300" height="250" alt="" src="https://www25.a8.net/svt/bgt?aid=220920658768&wid=003&eno=01&mid=s00000000218004106000&mc=1"/></a>
        <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3NJ3GY+CP8YWY+1OK+OG23L" alt=""></img>
        </div>
        <div className={styles.affilitext}>
          こちらのサイトは、宿泊予約もできますが、<b>レストランでのディナーやランチに特化したサービスがウリ</b>です！ちょっとしたご褒美や記念にいいですね！
        </div>
      </div>
      <div className={styles.flexaffili}>
        <div className={styles.affiliimage}>
        <a href="https://px.a8.net/svt/ejp?a8mat=3T0EO2+EDDPGY+455G+644DT" rel="nofollow">
        <img border="0" width="300" height="250" alt="" src="https://www28.a8.net/svt/bgt?aid=230126402869&wid=001&eno=01&mid=s00000019330001027000&mc=1"/></a>
        <img border="0" width="1" height="1" src="https://www10.a8.net/0.gif?a8mat=3T0EO2+EDDPGY+455G+644DT" alt=""/>
        </div>
        <div className={styles.affilitext}>
          レジャーの予約サイト。<b>沢山のアクティビティがお得に予約できます！</b>色々と眺めているだけでも「こんなレジャーあったんだ！」と思うくらいアクティビティが充実しています！
        </div>
      </div>
      
    </div>
  )
}

export default AffiliTravel