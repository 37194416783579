import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import * as styles from '../../styles/youtubelinkbanner.module.scss';

const YoutubeLinkBanner = () => {


    return(
        <div className = {styles.youtubelinkbannerwrapper}>
            <a href = "http://www.youtube.com/channel/UCgWmX8O5qubjBKTZZ3KvX6Q?sub_confirmation=1" target = "_blank" rel="noopener noreferrer">
                <div className = {styles.subscribewrapper}>
                    <StaticImage className={styles.bannerimg}
                        src = "../../images/youtubebanner.jpg"
                        alt="YoutubeBanner"
                    />
                </div>
            </a>
        </div>
    )
}

export default YoutubeLinkBanner;