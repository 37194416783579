import React from "react"
import * as styles from '../../styles/pagebottomaffili.module.scss'

const PageBottomAffili = ()=>{
    return(
        <div className={styles.pagebottomaffiliwrapper}>
            <div className={styles.leadwrapper}>
                <div className={styles.ad}>広告</div>
                <p>旅やバイクに関する面白い取り組み、お得な情報をシェアします！</p>
            </div>
            <div className={styles.affiliwrapper}>
                <div className={styles.affili}>
                <a href="https://px.a8.net/svt/ejp?a8mat=3N82JN+1UOKJ6+1BFI+69WPT" rel="nofollow">
                <img border="0" width="300" height="250" alt="" src="https://www26.a8.net/svt/bgt?aid=220406243112&wid=001&eno=01&mid=s00000006147001054000&mc=1" /></a>
                <img border="0" width="1" height="1" src="https://www11.a8.net/0.gif?a8mat=3N82JN+1UOKJ6+1BFI+69WPT" alt=""></img>
                    <div className={styles.text}>
                        カスタムバイクの高価買取、オンライン査定、出張買取など、バイクを売ろうかな…と思っている人に嬉しいサービスが沢山！
                    </div>
                </div>               

                <div className={styles.affili}>
                <a href="https://px.a8.net/svt/ejp?a8mat=3NAAER+84XAEQ+4ZGA+5ZMCH" rel="nofollow">
                <img border="0" width="300" height="250" alt="" src="https://www29.a8.net/svt/bgt?aid=220509747492&wid=001&eno=01&mid=s00000023257001006000&mc=1"/></a>
                <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3NAAER+84XAEQ+4ZGA+5ZMCH" alt=""/>
                    <div className={styles.text}>
                        ホテルのサブスク、という取り組みは面白いですね！フリーで働いている人にもオススメ！
                    </div>
                </div>

                <div className={styles.affili}>
                    <a href="https://px.a8.net/svt/ejp?a8mat=3NAAER+G2QVU+4BUU+5ZMCH" rel="nofollow">
                    <img border="0" width="300" height="250" alt="" src="https://www24.a8.net/svt/bgt?aid=220509747027&wid=002&eno=01&mid=s00000020199001006000&mc=1" loading="lazy"/></a>
                    <img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3NAAER+G2QVU+4BUU+5ZMCH" alt="" loading="lazy"/>
                    <div className={styles.text}>
                        旅行先のアクティビティ、アトラクション、体験、交通手段、WiFiなどをだれでもすぐに見つけられて、すぐに使えるプラットフォームです！
                    </div>
                </div>

                <div className={styles.affili}>
                    <a href="https://px.a8.net/svt/ejp?a8mat=3N8F2S+98TCPM+2VES+61C2P" rel="nofollow">
                        <img border="0" width="300" height="250" alt="" src="https://www21.a8.net/svt/bgt?aid=220422484559&wid=002&eno=01&mid=s00000013402001014000&mc=1" loading="lazy"/>
                    </a>
                    <img border="0" width="1" height="1" src="https://www16.a8.net/0.gif?a8mat=3N8F2S+98TCPM+2VES+61C2P" alt="" loading="lazy"/>
                    <div className={styles.text}>
                        合宿免許って色々あるんですね…楽しそうな取り組みも多いです！もちろん二輪免許取得の合宿もあります！
                    </div>
                </div>

                <div className={styles.affili}>
                    <a href="https://px.a8.net/svt/ejp?a8mat=3NAAER+7YYZ4Q+1OK+6VRR5" rel="nofollow">
                    <img border="0" width="300" height="250" alt="" src="https://www28.a8.net/svt/bgt?aid=220509747482&wid=002&eno=01&mid=s00000000218001156000&mc=1" loading="lazy"/></a>
                    <img border="0" width="1" height="1" src="https://www19.a8.net/0.gif?a8mat=3NAAER+7YYZ4Q+1OK+6VRR5" alt="" loading="lazy"/>
                    <div className={styles.text}>
                        見やすいHPに、厳選されたホテル。ちょっと贅沢な旅に。
                    </div>
                </div>

            </div>

        </div>
    )
}

export default PageBottomAffili